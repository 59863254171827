import React, { memo, ReactNode, useEffect, useMemo, useState } from "react";

import styles from './ShadowElement.scss';

interface IShadowElementProps {
  targetRef: React.MutableRefObject<HTMLDivElement>;
  children: ReactNode;
}

const ShadowElement = ({targetRef, children = null}: IShadowElementProps) => {
  const [rect, setRect] = useState<{width: number; height: number; left: number; top: number}>();

  useEffect(() => {
    const { current } = targetRef;
    if (!current) return;
    setRect(current.getBoundingClientRect());
  }, [targetRef]);

  
  const style = useMemo(() => {
    return {
      width: rect?.width,
      height: rect?.height,
    };
  }, [rect]);

  if (!targetRef.current || !rect) return null; 

  return (
    <div style={style} className={styles.element}>{children}</div>
  )
}

export default memo(ShadowElement);
