import React from 'react';
import cn from 'classnames';
import {ChipTypes, TChipContentShape} from './Chip.types';
import {useChipColor} from './Chip.context';
import styles from './Chip.scss';

const ChipContentShape: React.FC<TChipContentShape> = ({type = 'circle'}) => {
  const {foregroundColor = '', type: chipType = ChipTypes.default} = useChipColor();

  /**
   * Foreground color will be applied only if it's defined, and will override the background color
   * defined in the class due to CSS specificity.
   */
  const foregroundColorStyle = foregroundColor ? {backgroundColor: foregroundColor} : {};
  const shapeStyles = cn(styles.contentShape, styles[type], styles.backgroundColor, styles[`${chipType}`]);

  return <div className={shapeStyles} style={foregroundColorStyle} />;
};

export default ChipContentShape;
