import React, {useMemo} from 'react';
import cn from 'classnames';
import {components, SingleValueProps} from 'react-select';
import {SelectBaseOption, ISelectProps} from '../SelectField.types';
import {AvatarLabelPrefix, IconLabelPrefix} from './SingleValue.LabelPrefix';
import styles from '../SelectField.scss';

/**
 * Typing for react-select components is not working properly.
 */
const SingleValue = ({children, selectProps, ...rest}: SingleValueProps) => {
  const {dataTestId} = selectProps as ISelectProps;
  const {getValue} = rest;

  const valueArray = getValue() || [];
  const value = valueArray[0] || {};

  const {LabelPrefix, avatarProps, iconProps} = value as SelectBaseOption;

  const BaseLabelPrefix = useMemo(() => {
    if (LabelPrefix) {
      return LabelPrefix;
    }
    if (avatarProps) {
      return <AvatarLabelPrefix dataTestId={dataTestId} {...avatarProps} />;
    }
    if (iconProps) {
      return <IconLabelPrefix dataTestId={dataTestId} {...iconProps} />;
    }
    return null;
  }, [LabelPrefix, avatarProps, dataTestId, iconProps]);

  return (
    <components.SingleValue
      className={cn('flex align-items-center', styles.singleValue)}
      selectProps={selectProps}
      {...rest}
    >
      {BaseLabelPrefix}
      {!!BaseLabelPrefix && <span className="paddingRight-tiny1" />}
      {children}
    </components.SingleValue>
  );
};

export default SingleValue;
