import React, {useRef, useLayoutEffect} from 'react';
import cn from 'classnames';
import {Label, ErrorMessage} from '../Parts';
import {ITextAreaProps} from './TextArea.types';
import {Size} from '../base.types';
import styles from './TextArea.scss';

const TextArea: React.FC<ITextAreaProps> = ({
  elementSize = Size.MEDIUM,
  label,
  labelComponent,
  dangerouslySetLabel,
  error,
  value,
  onChange = () => {},
  id,
  placeholder,
  containerClass = '',
  textareaClass = '',
  dataTestId = 'StyleGuideTextArea',
  rows = 3,
  inlineEditable = false,
  ...rest
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const initialAttributes = useRef({offsetHeight: 0, borderWidth: 1});
  const textareaIdRef = useRef(id || `TextArea-${Math.random()}`);
  const textareaId = textareaIdRef.current;

  // Save textarea attributes that are needed for resizing
  useLayoutEffect(() => {
    const {offsetHeight} = textareaRef.current!;
    const {borderWidth: borderWidthPixels} = getComputedStyle(textareaRef.current!);
    const borderWidth = parseInt(borderWidthPixels, 10);
    initialAttributes.current = {offsetHeight, borderWidth};
  }, []);

  // This is where the resizing happens.
  useLayoutEffect(() => {
    const {offsetHeight: initialOffsetHeight, borderWidth} = initialAttributes.current;
    const currentScrollHeight = textareaRef.current?.scrollHeight;
    if (textareaRef && currentScrollHeight && currentScrollHeight > initialOffsetHeight) {
      /*
        Set the height to auto to reset the height.
        The user may remove text that'll delete a row.
      */
      const current = textareaRef.current!;
      current!.style.height = 'auto';
      const totalHeight = current.scrollHeight + borderWidth * 2;
      current.style.setProperty('height', `${totalHeight}px`);
    }
  }, [value]);

  // Styles
  const textAreaStyles = cn(
    styles.textarea,
    {
      [styles.xsmall]: elementSize === Size.XSMALL,
      [styles.small]: elementSize === Size.SMALL,
      [styles.medium]: elementSize === Size.MEDIUM,
      [styles.large]: elementSize === Size.LARGE,
      [styles.withError]: !!error,
      [styles.inlineEditable]: inlineEditable,
    },
    textareaClass,
  );

  return (
    <div data-testid={dataTestId} className={containerClass}>
      <Label
        label={label}
        labelComponent={labelComponent}
        dangerouslySetLabel={dangerouslySetLabel}
        elementSize={elementSize}
        inputId={textareaId}
      />
      <textarea
        id={textareaId}
        rows={rows}
        ref={textareaRef}
        className={textAreaStyles}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
      />
      <ErrorMessage error={error} />
    </div>
  );
};

export default TextArea;
