import React from 'react';
import {TEST_IDS} from './SideSheet.constants';
import Button, {BUTTON_THEMES} from '../Button/Button';
import styles from './SideSheet.scss';
import {SideSheetProps} from './SideSheet.types';

const SideSheetFooter = ({
  ctaButtonText,
  ctaButtonTheme = BUTTON_THEMES.PRIMARY,
  onCtaButtonClick,
  footerContent,
  ctaButtonProps = {},
}: Partial<SideSheetProps>) => (
  <>
    <div className={styles.fullWidthBorder} />
    <section className={styles.footer} id={TEST_IDS.FOOTER_ID} data-testid={TEST_IDS.FOOTER_ID}>
      {footerContent || (
        <Button onClick={onCtaButtonClick} theme={ctaButtonTheme} {...ctaButtonProps}>
          {ctaButtonText}
        </Button>
      )}
    </section>
  </>
);

export default SideSheetFooter;
