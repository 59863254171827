import React, {memo} from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import {Size} from '../base.types';
import Icon from '../Icon/Icon';
import {IModalProps} from './Modal.types';
import styles from './Modal.scss';

const Modal: React.FC<IModalProps> = ({
  children,
  dataTestId = 'StyleGuideModal',
  elementSize = Size.M,
  footerClasses = '',
  footerElement1,
  footerElement2,
  footerElement3,
  footerElement1Classes = '',
  footerElement2Classes = '',
  footerElement3Classes = '',
  bodyContainerClasses = '',
  header,
  headerClasses = '',
  hide = () => {},
  isVisible = false,
  modalClassName = '',
  modalCloseButtonClasses = '',
  modalCloseIconClasses = '',
  onRequestClose = () => {},
  subheader,
  subheaderClasses = '',
  subheaderComponent,
  ...props
}) => {
  const slideAnimation = isVisible ? styles.slideUp : styles.slideDown;
  const modalStyles = cn(
    styles.commonModalStyles,
    {
      [styles.modalLarge]: elementSize === Size.L,
      [styles.modalMedium]: elementSize === Size.M,
      [styles.modalSmall]: elementSize === Size.S || elementSize === Size.XS,
    },
    slideAnimation,
    modalClassName,
  );
  const headerStyles = cn('h3', headerClasses);
  const subheaderStyles = cn('p0 n700', subheaderClasses);
  const closeButtonStyles = cn(styles.modalCloseButton, modalCloseButtonClasses);
  const closeIconStyles = cn('paddingLeft-small1', styles.modalCloseIcon, modalCloseIconClasses);
  const footerContainerStyles = cn(footerClasses, {
    [styles.footerContainer]: footerElement1 || footerElement2 || footerElement3,
  });
  const footerElement1Styles = cn(styles.footerElement1, footerElement1Classes);
  const footerElement2Styles = cn(styles.footerElement2and3, footerElement2Classes);
  const footerElement3Styles = cn(styles.footerElement2and3, footerElement3Classes);

  return (
    <ReactModal
      ariaHideApp={false}
      contentLabel={header}
      isOpen={isVisible}
      overlayClassName={styles.overlay}
      className={modalStyles}
      onRequestClose={onRequestClose}
      testId={dataTestId}
      {...props}
    >
      <div className={styles.headerContainer} data-testid={`${dataTestId}-headerContainer`}>
        <div className={styles.headers}>
          {header && (
            <h3 className={headerStyles} data-testid={`${dataTestId}-header`}>
              {header}
            </h3>
          )}
          {subheader && (
            <p className={subheaderStyles} data-testid={`${dataTestId}-subheader`}>
              {subheader}
            </p>
          )}
          {subheaderComponent}
        </div>
        <button
          type="button"
          className={closeButtonStyles}
          onClick={hide}
          data-testid={`${dataTestId}-close`}
          aria-label="Close Modal Icon"
        >
          <Icon name="v2-close-icon" className={closeIconStyles} />
        </button>
      </div>
      {children && (
        <div className={cn(bodyContainerClasses, styles.bodyContainer)} data-testid={`${dataTestId}-bodyContainer`}>
          {children}
        </div>
      )}
      <div className={footerContainerStyles} data-testid={`${dataTestId}-footerContainer`}>
        <div className={footerElement1Styles}>{footerElement1}</div>
        <div className={styles.footer2and3Container}>
          {footerElement2 && <div className={footerElement2Styles}>{footerElement2}</div>}
          {footerElement3 && <div className={footerElement3Styles}>{footerElement3}</div>}
        </div>
      </div>
    </ReactModal>
  );
};

export default memo(Modal);
