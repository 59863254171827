import isObject from 'lodash.isobject';
import {IPrepareValue, SelectBaseOption, SelectGroupOption} from './SelectField.types';

/*
  In order to show the Placeholder text the default value must be falsey (e.g., null, undefined, false, ''). Passing
  a truthy value (e.g. {value: '', label: ''}) as the default object will cause the placeholder to disappear. I'm
  assuming that the library is considering {value: '', label: ''} to be a valid selection and therefore not showing.
*/
const defaultValue = '';
// react-select expects the value to be an object or an array of objects
export const prepareValue = ({value, options, multiple = false}: IPrepareValue) => {
  // Ensure working value is an array
  const valueArray = Array.isArray(value) ? value : [value].filter((filter) => filter);

  // Map all values to objects if they are not
  const valueObjects = valueArray.map((map) => (isObject(map) ? map : {value: map}));

  // Flat grouped options
  // @ts-ignore
  const flatOptions = (options[0] ?? {}).options
    ? options
        // @ts-ignore
        .map((group: SelectBaseOption | SelectGroupOption) => group.options)
        // @ts-ignore
        .flat()
    : options;

  // Map values to options
  // @ts-ignore
  const valueMapped = valueObjects
    .map((map) => flatOptions?.find((find) => find.value === map.value))
    .filter((filter) => filter);
  return multiple ? valueMapped : valueMapped[0] ?? defaultValue;
};
