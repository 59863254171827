import React from 'react';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './Checkbox.scss';
import {IBoxProps} from './Checkbox.types';

const Box: React.FC<IBoxProps> = ({checked, indeterminate = false, className = ''}) => {
  const boxStyles = cn(styles.box, className, {
    [styles.boxChecked]: checked || indeterminate,
  });

  const iconComponent = (() => {
    if (checked) {
      return <Icon name="checkmark-v2" className={styles.icon} />;
    }
    if (indeterminate) {
      return <Icon name="minus" className={cn(styles.icon, styles.indeterminateIcon)} />;
    }
    return null;
  })();

  return <div className={boxStyles}>{iconComponent}</div>;
};

export default Box;
