import React from 'react';
import cn from 'classnames';
import Avatar from '../../Avatar/Avatar';
import Icon from '../../Icon/Icon';
import styles from '../SelectField.scss';
import {TAvatarLabelPrefix, TIconLabelPrefix} from '../SelectField.types';
import {Size} from '../../base.types';

/**
 * Export components so dev can determine whether to use the object props or LabelPrefix for more
 * customization. These components have built in styles that fit with the select field label.
 */

export const AvatarLabelPrefix = ({dataTestId, ...rest}: TAvatarLabelPrefix) => {
  return <Avatar size={Size.XSMALL} dataTestId={`${dataTestId}-AvatarLabelPrefix`} {...rest} />;
};

export const IconLabelPrefix = ({className, dataTestId, ...rest}: TIconLabelPrefix) => {
  const classNames = cn(styles.htSelectIconLabelPrefix, className);

  return <Icon className={classNames} dataTestId={`${dataTestId}-IconLabelPrefix`} {...rest} />;
};
