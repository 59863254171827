import React, {useMemo} from 'react';
import cn from 'classnames';
import capitalize from 'lodash.capitalize';
import {ChipTypes, IChipProps, GetStyles} from './Chip.types';
import {Size} from '../base.types';
import styles from './Chip.scss';
import Icon from '../Icon/Icon';
import ChipColorContext from './Chip.context';

const getStyles = ({variant = 'outlined', size = Size.MEDIUM, classes = '', type = ChipTypes.default}: GetStyles) => {
  const color = ChipTypes[type];
  return cn(styles[`${variant}_${color}`], styles[`size${capitalize(size)}`], classes);
};

const Chip: React.FC<IChipProps> = ({
  children,
  dataTestId = 'StyleGuideChip',
  backgroundColor,
  foregroundColor,
  onClick = undefined,
  iconName,
  ...rest
}) => {
  /** If onClick is defined, the chip is clickable and should have a hover state */
  const isClickable = Boolean(onClick);

  const containerStyles = cn(getStyles(rest as GetStyles), {[styles.chipHover]: isClickable});

  const style = useMemo(() => {
    const value = {};
    if (backgroundColor)
      Object.assign(value, {
        backgroundColor,
      });
    if (foregroundColor)
      Object.assign(value, {
        color: foregroundColor,
        borderColor: foregroundColor,
      });
    return value;
  }, [backgroundColor, foregroundColor]);

  if (!children) return null;

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Trigger click event on "Enter" or "Space" key press. For accessibility purposes.
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      onClick?.();
    }
  };

  return (
    <ChipColorContext.Provider value={{foregroundColor, type: rest.type}}>
      <div
        data-testid={dataTestId}
        className={containerStyles}
        style={style}
        onClick={onClick}
        onKeyDown={handleKeyDown}
        role="button"
        tabIndex={0}
      >
        {isClickable && <div className={styles.hoverOverlay} />}
        <div className={styles.childrenContainer}>{children}</div>
        {iconName && <Icon name={iconName} className="marginLeft-tiny" />}
      </div>
    </ChipColorContext.Provider>
  );
};

export default React.memo(Chip);
