import React from 'react';
import cn from 'classnames';
import {MultiValueGenericProps} from 'react-select';
import {ISelectProps} from '../SelectField.types';
import styles from '../SelectField.scss';

const MultiValueContainer = ({children, innerProps, selectProps}: MultiValueGenericProps) => {
  const {elementSize} = selectProps as ISelectProps;

  const multiValueClassName = styles[`multiValue${elementSize}`];

  return (
    <div {...innerProps} className={cn(styles.htMultiValueContainer, multiValueClassName)}>
      {children}
    </div>
  );
};

export default MultiValueContainer;
