import {toast, ToastContent, ToastOptions, Id} from 'react-toastify';
import isObject from 'lodash.isobject';
import {TOAST_CONTAINER_ID} from './Toast.constants';

type BaseOptsType = {containerId: Id};
const baseOpts: BaseOptsType = {containerId: TOAST_CONTAINER_ID};

/**
 * Partial wrapper around react-toastify's toast function that sets the containerId to the default value (TOAST_CONTAINER_ID).
 * Not all `toast` options are exposed here. Update the styleguide if needed
 *
 * Usage: htToast('Hello World', {autoClose: 5000})
 */
const htToast = (message: ToastContent, opts: ToastOptions = {}) => toast(message, {...baseOpts, ...opts});

/**
 * Dismiss a toast
 * - Pass no params to clear all toasts
 * - Pass a toast `id` to clear a specific toast
 * - Pass an object with `containerId` (default `TOAST_CONTAINER_ID`) with optional toast `id` to clear.
 */
htToast.dismiss = (params?: Id | {id?: Id; containerId: Id}) => {
  if (isObject(params)) {
    const newParams = {...baseOpts, ...params};
    toast.dismiss(newParams);
  } else {
    toast.dismiss(params);
  }
};

/**
 * Pass a toast `id` to check if a specific toast is active
 */
htToast.isActive = (id: Id) => toast.isActive(id);

export {htToast};
