import {BREAKPOINTS} from '../Grid/Grid';

export const getScreenSizes = () => {
  const width =
    typeof window === 'object'
      ? window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
      : null;
  const height =
    typeof window === 'object'
      ? window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
      : null;
  return {width, height};
};

export const isMobile = () => getScreenSizes().width! < BREAKPOINTS.MD_GRID;
export const isTablet = () => {
  const {width} = getScreenSizes();
  return width! >= BREAKPOINTS.MD_GRID && width! < BREAKPOINTS.LG_GRID;
};
export const isDesktop = () => getScreenSizes().width! >= BREAKPOINTS.LG_GRID;
export const isMobileOrTablet = () => getScreenSizes().width! < BREAKPOINTS.LG_GRID;
