import React from 'react';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import styles from './SideSheet.scss';
import {TEST_IDS} from './SideSheet.constants';
import {SideSheetProps} from './SideSheet.types';

const SideSheetHeader = ({
  hide = () => {},
  headerText,
  subheaderText,
  showCloseButton = true,
}: Partial<SideSheetProps>) => {
  return (
    <>
      <section className={styles.header} id={TEST_IDS.HEADER_ID} data-testid={TEST_IDS.HEADER_ID}>
        <div className={styles.headerTextContainer}>
          <p className={cn('h4 n800', {'marginBottom-tiny1': subheaderText})}>{headerText}</p>
          {subheaderText && <p className="p2 n700">{subheaderText}</p>}
        </div>
        <div>
          {showCloseButton && (
            <Icon
              name="v2-close-icon"
              className={cn('h4', styles.closeIcon)}
              onClick={hide}
              dataTestId={TEST_IDS.CLOSE_ICON}
            />
          )}
        </div>
      </section>
      <div className={styles.fullWidthBorder} />
    </>
  );
};

export default SideSheetHeader;
