import React from 'react';
import {TChipColorContext} from './Chip.types';

const ChipColorContext = React.createContext<TChipColorContext>({
  foregroundColor: '',
  type: '',
});

export const useChipColor = () => React.useContext(ChipColorContext);

export default ChipColorContext;
