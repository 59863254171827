import React, {Children, cloneElement, memo, ReactElement} from 'react';
import Grid from '../Grid/Grid';
import Label from '../Parts/Label';
import useGetGroupedChildren from '../utils/useGetGroupedChildren';
import {IRadioGroupProps} from './Radio.types';
import {Size} from '../base.types';

const RadioGroup: React.FC<IRadioGroupProps> = ({
  label,
  labelComponent,
  dangerouslySetLabel,
  children,
  value,
  onChange,
  disableSort = true,
  disableGrouping = true,
  enableDeselect = false,
  containerClassName = '',
  columnClassName = '',
  rowClassName = '',
  elementSize = Size.M,
  dataTestId = 'StyleGuideRadioGroup',
}) => {
  // We want to pass down extra props to the children (Radio)
  const childrenWithExtras = Children.map(children, (child: ReactElement) => {
    return cloneElement(child, {
      selected: value === child.props.value,
      onChange,
      elementSize,
      enableDeselect,
    });
  });

  // Group the children if necessary. See the utils
  const {groupedChildren, columnProps} = useGetGroupedChildren({
    children: childrenWithExtras,
    disableSort,
    disableGrouping,
  });

  return (
    <div className={containerClassName} data-testid={dataTestId}>
      <Label
        elementSize={elementSize}
        label={label}
        labelComponent={labelComponent}
        dangerouslySetLabel={dangerouslySetLabel}
      />
      <Grid.FullWidth>
        <Grid.Row classes={rowClassName}>
          {(groupedChildren as ReactElement[][]).map((group, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid.Column classes={columnClassName} {...columnProps} key={i}>
              {group}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid.FullWidth>
    </div>
  );
};

export default memo(RadioGroup);

/**
  RadioGroup

  This group is used with Radio. If the options count exceed a certain limit,
  the options will be displayed in multiple columns instead of a single column. Also,
  the options will be sorted alphabetically when they are broken down into columns.
  Props are provided to override these default settings.

  Note:
  Radio must be a direct child of RadioGroup for this to work, or
  just take extra care if you're using a custom checkbox component.

  Props:
  label - A label of type string to render.
  labelComponent - A custom component to render for the label.
  dangerouslySetLabel - A string to dangerously set its inner html.
  value - Value of the selected radio
  onChange - Function to when selecting. First argument is the selected value.
  disableSort - Disable sorting of list when breaking into multiple columns.
  disableGrouping - Disable breaking into multiple columns. Show options in a single column regardless of options count.
  size - Form size. Affects container label and list spacing

  ---------

  General Usage:

  import { Radio, RadioGroup } from 'components/Elements/Radio';

  ...

  const [radio, setRadio] = useState(1);
  const onChange = (selectedValue) => {
    setRadio(selectedValue);
  };

  return (
    <RadioGroup
      label="An Optional Label"
      value={radio}
      onChange={onChange}
      containerClassName={styles.container}
    >
      <Radio value={1} label="Number 1" />
      <Radio value={2} label="Number 2" />
      <Radio value={3} label="Number 3" boldLabel />
      <Radio value={4} label="Number 4">
        <p className="caption">Some custom component to show below the label</p>
      </Radio>
    </RadioGroup>
  );
*/
