import React, {useMemo, useRef} from 'react';
import cn from 'classnames';
import {TipBox} from '../Tooltip/Tooltip';
import {useElementStatus} from './DonutChart.hooks';
import {DEFAULT_SIZE, DEFAULT_BAR_SIZE} from './DonutChart.constants';
import {IDonutChartProps, IDonutChartSegmentProps, ISegmentSlice} from './DonutChart.types';
import styles from './DonutChart.scss';
import ShadowElement from './Parts/ShadowElement';

const degToRad = (deg: number) => deg * (Math.PI / 180);

const DonutChartSegment = ({segment, dataTestId}: IDonutChartSegmentProps) => {
  const ref = useRef<HTMLDivElement>();
  const {hovered: show} = useElementStatus({ref});

  const style = useMemo(() => {
    const {startDeg, endDeg} = segment.cone;
    const clipPaths = [
      {
        x: 50,
        y: 50,
      },
    ];

    const cc = (deg: number) => ({
      x: Math.cos(degToRad(deg - 90)) * 1000,
      y: Math.sin(degToRad(deg - 90)) * 1000,
    });

    clipPaths.push(cc(startDeg));
    // Adds extra polygon points when the cone is going through corners
    if (startDeg <= 0 && endDeg >= 90)
      clipPaths.push({
        x: 1000,
        y: 0,
      });
    if (startDeg <= 90 && endDeg >= 180)
      clipPaths.push({
        x: 1000,
        y: 1000,
      });
    if (startDeg <= 180 && endDeg >= 270)
      clipPaths.push({
        x: -1000,
        y: 0,
      });
    clipPaths.push(cc(endDeg));

    return {
      backgroundColor: segment.color,
      clipPath: `polygon(${clipPaths.map((path) => `${path.x}% ${path.y}%`).join(', ')})`,
    };
  }, [segment.color, segment.cone]);

  return (
    <>
      <div ref={ref} className={styles.segment} style={style} data-testid={dataTestId} />
      {segment.label && (
        <ShadowElement targetRef={ref}>
          <TipBox show={show} content={segment.label} position="right" align="right" />
        </ShadowElement>
      )}
    </>
  );
};

const DonutChart = ({
  barSize = DEFAULT_BAR_SIZE,
  size = DEFAULT_SIZE,
  segments = [],
  featuredLabelOverride,
  hideFeaturedLabel,
  dataTestId,
}: IDonutChartProps) => {
  /**
   * Get featured segment, which is either the first one or flagged as `featured`
   */
  const featuredSegment = useMemo(() => segments.find((segment) => segment.featured) || segments[0], [segments]);

  /**
   * Determine the start and end degrees for each segment.
   */
  const conedSegments = useMemo(() => {
    const partialPortions = segments.reduce((ret, segment) => {
      const prevDeg = ret.length > 0 ? ret.slice(-1)[0].cone.endDeg : 0;
      return [
        ...ret,
        {
          ...segment,
          cone: {
            startDeg: prevDeg,
            endDeg: prevDeg + (360 * segment.percentage) / 100,
          },
        },
      ];
    }, [] as ISegmentSlice[]);

    const prevDeg = partialPortions.length > 0 ? partialPortions.slice(-1)[0].cone.endDeg : 0;

    partialPortions.push({
      cone: {
        startDeg: prevDeg,
        endDeg: 360,
      },
      percentage: -1,
    });
    return partialPortions;
  }, [segments]);

  const donutStyle = useMemo(
    () => ({
      width: `${size}px`,
      height: `${size}px`,
    }),
    [size],
  );

  const barWidthStyle = useMemo(
    () => ({
      width: `${size - barSize * 2}px`,
      height: `${size - barSize * 2}px`,
    }),
    [barSize, size],
  );

  const innerLabel = useMemo(() => {
    if (hideFeaturedLabel) return null;
    if (featuredLabelOverride) return featuredLabelOverride;
    return featuredSegment ? `${Math.round(featuredSegment.percentage)}%` : null;
  }, [featuredLabelOverride, featuredSegment, hideFeaturedLabel]);

  return (
    <div className={styles.donut} style={donutStyle} data-testid={dataTestId}>
      {conedSegments.map((segment, index) => (
        <DonutChartSegment
          key={`slice-${index + 0}`}
          segment={segment}
          dataTestId={segment.percentage !== -1 ? `${dataTestId}-segment-${index}` : undefined}
        />
      ))}
      <div className={cn('p2', styles.labelContainer)} style={barWidthStyle} data-testid={`${dataTestId}-inner-label`}>
        {size >= 60 ? (
          innerLabel
        ) : (
          <svg viewBox="0 0 40 18">
            <text x="50%" y="60%" dominantBaseline="middle" textAnchor="middle">
              {innerLabel}
            </text>
          </svg>
        )}
      </div>
    </div>
  );
};

export default React.memo(DonutChart);
