import React, {useState, useEffect} from 'react';
import cn from 'classnames';
import {IRadioProps} from './Radio.types';
import {Size} from '../base.types';
import Circle from './Circle';
import styles from './Radio.scss';

let inputIdCounter = 0;

const Radio: React.FC<IRadioProps> = ({
  children,
  label,
  selected = false,
  value: radioValue,
  disabled,
  onChange = () => {},
  enableDeselect = false,
  wrapperClass = '',
  labelClass = '',
  elementSize = Size.M,
  boldLabel = false,
  id = '',
  dataTestId = 'StyleGuideRadio',
  ...rest
}) => {
  const [inputId, setInputId] = useState(id);

  useEffect(() => {
    if (!id) {
      setInputId(`Radio-${inputIdCounter}`);
      inputIdCounter += 1;
    }
  }, []);

  const labelWrapperStyles = cn(styles.labelWrapper, styles[elementSize], wrapperClass, {
    [styles.disabled]: disabled,
  });

  const labelStyles = cn(styles.label, labelClass, {
    [styles.bold]: boldLabel,
  });

  const onClick = (rValue: any) => (event: React.MouseEvent<HTMLInputElement>) => {
    const currentTarget = event.target as EventTarget & HTMLInputElement;
    const {checked} = currentTarget;
    if (enableDeselect && selected) {
      onChange(null); // deselect
    } else {
      onChange(checked ? rValue : null);
    }
  };

  return (
    <div data-testid={dataTestId}>
      <label htmlFor={inputId} className={labelWrapperStyles}>
        <div>
          <input
            id={inputId}
            type="radio"
            disabled={disabled}
            defaultChecked={selected}
            onClick={onClick(radioValue)}
            value={radioValue}
            className={styles.input}
            {...rest}
          />
          <Circle selected={selected} />
        </div>
        <div>
          <span className={labelStyles}>{label}</span>
        </div>
      </label>
      <div className={styles.childWrapper}>{children}</div>
    </div>
  );
};

export default Radio;
