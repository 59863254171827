import React from 'react';
import ReactSelect from 'react-select';
import cn from 'classnames';
import Label from '../Parts/Label';
import ErrorMessage from '../Parts/ErrorMessage';
import {Size} from '../base.types';
import {ISelectFieldProps} from './SelectField.types';
import {prepareValue} from './SelectField.utils';
import {useCustomValueSelect} from './hooks/useCustomValueSelect';

// Components replacements
import ClearIndicator from './Components/ClearIndicator';
import DropdownIndicator from './Components/DropdownIndicator';
import IndicatorSeparator from './Components/IndicatorSeparator';
import MultiValueContainer from './Components/MultiValueContainer';
import MultiValueLabel from './Components/MultiValueLabel';
import MultiValueRemove from './Components/MultiValueRemove';
import NoOptionsMessage from './Components/NoOptionsMessage';
import Option from './Components/Option';
import OptionLabel from './Components/OptionLabel';
import SingleValue from './Components/SingleValue';

import styles from './SelectField.scss';

const SelectField: React.FC<ISelectFieldProps> = ({
  id,
  groups,
  options,
  invalid,
  error,
  label,
  labelComponent,
  hint,
  className,
  reactSelectClassName,
  elementSize = Size.M,
  searchable = false,
  clearable = false,
  value,
  onChange = () => {},
  dataTestId = 'StyleGuideSelectField',
  multiple = false,
  inlineEditable = false,
  showAddCustomValue = false,
  ...rest
}) => {
  const {fieldOptions, handleInputChange, handleSelectChange} = useCustomValueSelect({
    options,
    groups,
    onChange,
    multiple,
    showAddCustomValue,
  });

  const selectStyles = cn(
    styles.htSelect,
    {
      [styles.invalid]: error || invalid,
      [styles.searchable]: searchable,
      [styles.inlineEditable]: inlineEditable,
    },
    styles[elementSize],
    reactSelectClassName,
  );

  const fieldValue = prepareValue({value: value!, options: fieldOptions, multiple});

  return (
    <div id={id} className={className}>
      <Label elementSize={elementSize} dangerouslySetLabel={label} labelComponent={labelComponent} />
      {hint && <p className={cn('p2', styles.hint)}>{hint}</p>}
      <div className={styles.innerSelectWrapper} data-testid={dataTestId}>
        <ReactSelect
          isMulti={multiple}
          onChange={handleSelectChange}
          value={fieldValue}
          isClearable={clearable}
          isSearchable={searchable}
          className={selectStyles}
          classNamePrefix="react-select"
          blurInputOnSelect
          // @ts-ignore
          elementSize={elementSize}
          dataTestId={dataTestId}
          components={{
            ClearIndicator,
            DropdownIndicator,
            IndicatorSeparator,
            MultiValueContainer,
            MultiValueLabel,
            MultiValueRemove,
            NoOptionsMessage,
            Option,
            SingleValue,
          }}
          formatOptionLabel={OptionLabel}
          onInputChange={handleInputChange}
          options={fieldOptions}
          {...rest}
        />
        <ErrorMessage error={error} />
      </div>
    </div>
  );
};

export default SelectField;
