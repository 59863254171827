import React from 'react';
import {ToastContainer, cssTransition, ToastContainerProps, CloseButtonProps} from 'react-toastify';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import {TOAST_CONTAINER_ID} from './Toast.constants';
import styles from './Toast.scss';

// Slide Transition that closely resembles what the Design team spec'd out
const CustomTransition = cssTransition({
  enter: styles.animateUp,
  exit: styles.animateDown,
});

// Close Button
const CloseButton = ({closeToast}: CloseButtonProps) => (
  <button type="button" onClick={closeToast} className="plainButton" aria-label="Close Toast Icon">
    <Icon name="v2-close-icon" className={cn('marginLeft-small', styles.closeButton)} />
  </button>
);

// Toast Container
const HTToastContainer = (props: ToastContainerProps = {}) => {
  const {className = '', ...restProps} = props;
  return (
    <ToastContainer
      autoClose={4000}
      closeButton={CloseButton}
      containerId={TOAST_CONTAINER_ID}
      draggable={false}
      hideProgressBar
      newestOnTop
      position="bottom-center"
      transition={CustomTransition}
      className={cn('marginBottom-small', styles.toastContainer, className)}
      toastClassName={cn('marginTop-small', styles.toast)}
      {...restProps}
    />
  );
};

export default HTToastContainer;
