// Libraries
import React from 'react';
import cn from 'classnames';
import {ILink, IButton, LinkProps} from './Button.types';
import Icon from '../Icon/Icon';
// Styles
import styles from './Button.scss';

type PassedParams =
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
  | (React.AnchorHTMLAttributes<HTMLAnchorElement> & LinkProps);

export const BUTTON_THEMES = {
  DEFAULT: 'primary',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  SCIENCEBLUE: 'scienceBlue',
  GRADIENTBLUE: 'gradientBlue',
  INVERTED: 'inverted',
  TRANSPARENTWHITE: 'transparentWhite',
  DANGER_PRIMARY: 'dangerPrimary',
  DANGER_SECONDARY: 'dangerSecondary',
};

const Button: React.FC<IButton | ILink> = ({
  theme = BUTTON_THEMES.DEFAULT,
  huge = false,
  medium = false,
  small = false,
  inlineBlock = false,
  isLink = false,
  LinkComponent = null,
  dataTestId = 'StyleGuideButton',
  className = '',
  goTo = '',
  children,
  icon,
  iconClassName = '',
  isLoading = false,
  ...rest
}) => {
  const buttonStyles = cn(
    styles.button,
    {
      [styles.buttonPrimary]: theme === BUTTON_THEMES.PRIMARY,
      [styles.buttonSecondary]: theme === BUTTON_THEMES.SECONDARY,
      [styles.buttonScienceBlue]: theme === BUTTON_THEMES.SCIENCEBLUE,
      [styles.buttonGradientBlue]: theme === BUTTON_THEMES.GRADIENTBLUE,
      [styles.buttonInverted]: theme === BUTTON_THEMES.INVERTED,
      [styles.buttonDangerPrimary]: theme === BUTTON_THEMES.DANGER_PRIMARY,
      [styles.buttonDangerSecondary]: theme === BUTTON_THEMES.DANGER_SECONDARY,
      [styles.buttonTransparentWhite]: theme === BUTTON_THEMES.TRANSPARENTWHITE,
      [styles.buttonHuge]: huge,
      [styles.buttonMedium]: medium,
      [styles.buttonSmall]: small,
      [styles.inlineBlock]: inlineBlock,
      [styles.isLink]: isLink,
      [styles.suppressPointerEvents]: isLoading,
    },
    className,
  );
  const buttonTextStyles = cn(styles.buttonText, {
    [styles.hiddenToMaintainSpace]: isLoading,
  });

  const iconStyles = cn(
    styles.iconStyles,
    {
      [styles.iconHuge]: huge,
      [styles.iconMedium]: medium,
      [styles.iconSmall]: small,
    },
    iconClassName,
  );

  if (isLink) {
    const newProps = rest as PassedParams;

    if (LinkComponent) {
      return (
        <LinkComponent to={goTo} className={buttonStyles} data-testid={`${dataTestId}-isLink`} {...newProps}>
          {children}
        </LinkComponent>
      );
    }

    return (
      <a href={goTo} className={buttonStyles} data-testid={`${dataTestId}-isLink`} {...newProps}>
        {children}
      </a>
    );
  }

  const newProps = rest as React.ButtonHTMLAttributes<HTMLButtonElement>;
  newProps.type = newProps.type || 'button';

  return (
    // eslint-disable-next-line react/button-has-type
    <button className={buttonStyles} data-testid={dataTestId} {...newProps}>
      <span className={buttonTextStyles}>
        {icon && <Icon name={icon} className={iconStyles} />}
        {children}
      </span>
      {isLoading && (
        <div className={styles.loaderDiv} data-testid="isLoadingButton">
          <span className={cn(styles.loaderAnimation, styles.loader)} />
        </div>
      )}
    </button>
  );
};

export default Button;
