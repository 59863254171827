import React, {useEffect, forwardRef, Ref, useState} from 'react';
import cn from 'classnames';
import MaskedInput from 'react-text-mask';
import {Size} from '../base.types';
import {prepareMask} from './utils';
import {Label, ErrorMessage} from '../Parts';
import PostfixIcon from './PostfixIcon';
import {IInputWrapperProps, IInputFieldProps} from './Input.types';
import styles from './Input.scss';

/**
 InputWrapper - Wrapper around <input /> and <PostfixIcon />
 */
const InputWrapper: React.FC<IInputWrapperProps> = ({
  appendedNode,
  elementSize = Size.MEDIUM,
  inputId,
  defaultValue,
  onChange = () => {},
  placeholder = '',
  mask = '',
  guide = true,
  inputWrapperClass = '',
  inputRef,
  error,
  iconName,
  iconClass,
  iconOnClick,
  iconComponent,
  inlineEditable = false,
  showLoader = false,
  dataTestIdInput = 'StyleGuideInput',
  disabled,
  prependedNode,
  showError = true,
  ...rest
}) => {
  const hasMask = !!mask;
  const maskType = prepareMask(mask);
  const hasError = !!error;

  // Set default value
  useEffect(() => {
    if (defaultValue) {
      const target = {
        target: {value: defaultValue},
      } as unknown as React.ChangeEvent<HTMLInputElement>;
      onChange(target);
    }
  }, []);

  const styleSize = styles[elementSize];

  // Styles
  const inputWrapperStyles = cn(styles.inputWrapper, inputWrapperClass, {
    [styles.withError]: hasError,
    [styles.disabled]: !!disabled,
    [styles.inlineEditable]: inlineEditable,
  });
  const inputStyles = cn(styles.input, styles[elementSize], {
    [styles.withPrepended]: !!prependedNode,
    [styles.withAppended]: !!(appendedNode || iconName || iconComponent),
  });

  return (
    <div className={inputWrapperStyles}>
      {prependedNode && <div className={cn(styles.sideElements, styles.prepended, styleSize)}>{prependedNode}</div>}
      {!hasMask ? (
        <input
          data-testid={dataTestIdInput}
          id={inputId}
          ref={inputRef}
          className={inputStyles}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          {...rest}
        />
      ) : (
        <MaskedInput
          data-testid={dataTestIdInput}
          id={inputId}
          ref={inputRef as Ref<MaskedInput>}
          className={inputStyles}
          defaultValue={defaultValue}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
          mask={maskType}
          guide={guide}
          placeholderChar={'\u2000'}
          disabled={disabled}
          {...rest}
        />
      )}
      {appendedNode ? (
        <div className={cn(styles.sideElements, styles.appended, styleSize)}>{appendedNode}</div>
      ) : (
        <PostfixIcon
          inputRef={inputRef}
          hasError={hasError}
          size={elementSize}
          iconName={iconName}
          iconClass={iconClass}
          iconOnClick={iconOnClick}
          iconComponent={iconComponent}
          showLoader={showLoader}
          showError={showError}
        />
      )}
    </div>
  );
};

let inputIdCounter = 0;

const InputField = forwardRef<HTMLInputElement, IInputFieldProps>(
  (
    {
      elementSize = Size.MEDIUM,
      containerClass = '',
      label,
      labelComponent,
      dangerouslySetLabel,
      error,
      id,
      dataTestId = 'StyleGuideInputField',
      ...restProps
    },
    inputRef,
  ) => {
    const [inputId, setInputId] = useState(id);

    // set id for <input />
    useEffect(() => {
      if (!id) {
        setInputId(`Input-${inputIdCounter}`);
        inputIdCounter += 1;
      }
    }, []);

    return (
      <div className={cn(styles.inputField, containerClass)} data-testid={dataTestId}>
        <Label
          elementSize={elementSize}
          label={label}
          labelComponent={labelComponent}
          dangerouslySetLabel={dangerouslySetLabel}
          inputId={inputId}
        />
        <InputWrapper inputRef={inputRef} elementSize={elementSize} error={error} inputId={inputId} {...restProps} />
        <ErrorMessage error={error} />
      </div>
    );
  },
);

InputField.displayName = 'InputField';

/*
  Doing some weird hack so that the docs can generate the args table.
*/
const Input = InputField;

export default Input;
