import React from 'react';
import cn from 'classnames';
import {IGrid, IColumnGrid} from './Grid.types';

export const BREAKPOINTS = {
  MD_GRID: 576,
  LG_GRID: 992,
  XL_GRID: 1440,
};

const FluidGrid: React.FC<IGrid> = ({children, classes = '', dataTestId = 'StyleGuideFluidGrid'}) => {
  const fluidGridStyles = cn('grid', classes);
  return (
    <div data-testid={dataTestId} className={fluidGridStyles}>
      {children}
    </div>
  );
};

const FullWidthGrid: React.FC<IGrid> = ({children, classes = '', dataTestId = 'StyleGuideFullGrid'}) => {
  const fullWidthGridStyles = cn('grid-full-width', classes);
  return (
    <div data-testid={dataTestId} className={fullWidthGridStyles}>
      {children}
    </div>
  );
};

const Row: React.FC<IGrid> = ({children, classes = ''}) => {
  const rowStyles = cn('row', classes);
  return <div className={rowStyles}>{children}</div>;
};

const Column: React.FC<IColumnGrid> = ({children, classes = '', sm, smOffset, md, mdOffset, lg, lgOffset}) => {
  const smStyles = sm && `col-sm-${sm}`;
  const smOffsetStyles = Number.isInteger(smOffset) && `col-sm-offset-${smOffset}`;
  const mdStyles = md && `col-md-${md}`;
  const mdOffsetStyles = Number.isInteger(mdOffset) && `col-md-offset-${mdOffset}`;
  const lgStyles = lg && `col-lg-${lg}`;
  const lgOffsetStyles = Number.isInteger(lgOffset) && `col-lg-offset-${lgOffset}`;
  const genericColumn = !sm && !md && !lg && 'col'; // .col is styled with flex:1
  const columnStyles = cn({
    [smStyles as any]: Boolean(smStyles),
    [smOffsetStyles as any]: Boolean(smOffsetStyles),
    [mdStyles as any]: Boolean(mdStyles),
    [mdOffsetStyles as any]: Boolean(mdOffsetStyles),
    [lgStyles as any]: Boolean(lgStyles),
    [lgOffsetStyles as any]: Boolean(lgOffsetStyles),
    [genericColumn as any]: Boolean(genericColumn),
    [classes as any]: Boolean(classes),
  });
  return <div className={columnStyles}>{children}</div>;
};

const Grid = {
  Fluid: FluidGrid,
  FullWidth: FullWidthGrid,
  Row,
  Column,
};

export default Grid;
