import React, {memo, useState, useCallback, cloneElement, ReactElement} from 'react';
import * as Types from './Accordion.types';

const AccordionGroup: React.FC<Types.IAccordionGroupProps> = ({
  className = '',
  children,
  dataTestId = 'StyleGuideAccordionGroup',
  noContentPadding,
  noIndentPadding,
  iconPosition,
  withBottomBorder,
}) => {
  const [openItem, setOpenItem] = useState<number | null>(null);
  const [selectedIndex, setSelectedIndex] = useState<number | null>();

  const selectClick = (x: number) => {
    setSelectedIndex(x);
  };

  const handleToggle = useCallback((index, visible) => {
    const item = visible ? index : null;
    setOpenItem(item);
  }, []);

  return (
    <div className={className} data-testid={dataTestId}>
      {children.map((child: ReactElement, index) => {
        return cloneElement(child as ReactElement, {
          key: child.props?.title || index,
          opened: openItem === index,
          onToggle: (visible: boolean) => handleToggle(index, visible),
          groupHasOpenItem: Number.isInteger(openItem),
          withBottomBorder,
          selectClick,
          index,
          alwaysOpen: selectedIndex === index,
          noChildContentPadding: noContentPadding,
          noChildIndentPadding: noIndentPadding,
          childIconPosition: iconPosition,
        });
      })}
    </div>
  );
};

export default memo(AccordionGroup);
