import React, {useState, useEffect, useRef} from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import throttle from 'lodash.throttle';
import {SideSheetProps} from './SideSheet.types';
import SideSheetHeader from './SideSheet.Header';
import SideSheetFooter from './SideSheet.Footer';
import {TEST_IDS, MODAL_CLOSE_DURATION_MS, CSS_ANIMATION_DURATION_MS} from './SideSheet.constants';
import styles from './SideSheet.scss';

/**
 * For maximum flexibility let's allow access to underlying React Modal props. However, let's not allow the potential that different values
 * could be passed for the same prop, e.g., <SideSheet isOpen={false} reactModalProps={{isOpen: true}}/>
 * `className` and `overlayClassName` are explicitly omitted because we want to control those ourselves by forcing
 * the dev to pass as props to the SideSheet component, where we merge them with our own.
 */
export const OMITTED_REACT_MODAL_PROPS = [
  'isOpen',
  'onRequestClose',
  'className',
  'overlayClassName',
  'ariaHideApp',
  'closeTimeoutMS',
];

/** Use this instead of lodash/omit or lodash/pick to avoid a dependency */
export const deleteKeysFromObject = (object: {[key: string]: any}, keys: string[]) => {
  const dupeObj = {...object};
  keys.forEach((k) => delete dupeObj[k]);
  return dupeObj;
};

const SideSheet = ({
  isOpen,
  showFooter = true,
  showCloseButton = true,
  headerText,
  subheaderText,
  ctaButtonText,
  ctaButtonTheme,
  ctaButtonProps = {},
  children,
  footerContent,
  reactModalProps = {},
  className,
  hide = () => {},
  onRequestClose = () => {},
  onCtaButtonClick = () => {},
  headerComponent,
  scrollThrottleMs = 0,
}: SideSheetProps) => {
  const slideAnimation = isOpen ? styles.slideLeft : styles.slideRight;
  const fadeAnimation = isOpen ? styles.fadeIn : styles.fadeOut;
  const modalStyles = cn(styles.modalStyles, slideAnimation, className);
  const overlayStyles = cn(styles.overlay, fadeAnimation);

  const filteredReactModalProps = deleteKeysFromObject(reactModalProps, OMITTED_REACT_MODAL_PROPS);

  const contentRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = throttle(() => {
      if (contentRef.current) {
        setScrollPosition(contentRef.current.scrollTop);
      }
    }, scrollThrottleMs) as EventListener; // <- 100 milliseconds throttling

    if (isOpen) {
      // Set a timeout to allow for CSS animation to complete
      setTimeout(() => {
        if (contentRef.current) {
          contentRef.current.addEventListener('scroll', handleScroll);
        }
      }, CSS_ANIMATION_DURATION_MS);
    }

    /**
     * Store the current ref in a variable so that we can reference it in the cleanup function.
     * This avoids issues with contentRef.current potentially changing by the time the cleanup function runs.
     */
    const currentRef = contentRef.current;
    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
    };
  }, [isOpen, scrollThrottleMs]);

  return (
    // @ts-ignore
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      className={modalStyles}
      overlayClassName={overlayStyles}
      ariaHideApp={false}
      closeTimeoutMS={MODAL_CLOSE_DURATION_MS}
      id={TEST_IDS.MODAL}
      data-testid={TEST_IDS.MODAL}
      {...filteredReactModalProps}
    >
      {headerComponent ? (
        headerComponent({hide, headerText, subheaderText, scrollPosition, contentRef})
      ) : (
        <SideSheetHeader
          headerText={headerText}
          subheaderText={subheaderText}
          hide={hide}
          showCloseButton={showCloseButton}
        />
      )}
      <div
        className={styles.childrenContainer}
        id={TEST_IDS.CONTENT_ID}
        data-testid={TEST_IDS.CONTENT_ID}
        ref={contentRef}
      >
        {children}
      </div>
      {showFooter && (
        <SideSheetFooter
          ctaButtonText={ctaButtonText}
          ctaButtonTheme={ctaButtonTheme}
          onCtaButtonClick={onCtaButtonClick}
          footerContent={footerContent}
          ctaButtonProps={ctaButtonProps}
        />
      )}
    </ReactModal>
  );
};

export default SideSheet;
