import React from 'react';
import cn from 'classnames';
import {ClearIndicatorProps} from 'react-select';
import Icon from '../../Icon/Icon';
import styles from '../SelectField.scss';

const ClearIndicator = ({innerProps}: ClearIndicatorProps) => (
  <Icon name="v2-close-icon" {...innerProps} className={cn(styles.htClearIndicator)} />
);
export default ClearIndicator;
