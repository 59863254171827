import React from 'react';
import cn from 'classnames';
import {DropdownIndicatorProps} from 'react-select';
import Icon from '../../Icon/Icon';
import styles from '../SelectField.scss';

const DropdownIndicator = ({innerProps, selectProps}: DropdownIndicatorProps) => (
  <Icon
    {...innerProps}
    dataTestId="StyleGuideSelectField-DropdownIndicator"
    name="chevron"
    className={cn(styles.htDropdownIndicator, {[styles.focused]: selectProps?.menuIsOpen})}
  />
);

export default DropdownIndicator;
