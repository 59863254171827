import React from 'react';
import cn from 'classnames';
import {TSideSheetContentGroup, TSideSheetContentSection} from './SideSheet.types';
import {TEST_IDS} from './SideSheet.constants';
import styles from './SideSheet.scss';

export const SideSheetContentSection: React.FC<TSideSheetContentSection> = ({title, isLastItem, children}) => {
  const itemStyles = cn(styles.contentSection, 'paddingBottom-small2', {
    'marginBottom-small2': !isLastItem,
    [styles.borderBottom]: !isLastItem,
  });
  return (
    <div data-testid={TEST_IDS.CONTENT_SECTION} className={itemStyles}>
      {title && <p className="h6 marginBottom-small">{title}</p>}
      <div>{children}</div>
    </div>
  );
};

export const SideSheetContentGroup: React.FC<TSideSheetContentGroup> = ({
  sections = [],
  className = 'marginTop-small2',
}) => {
  return (
    <div className={className} data-testid={TEST_IDS.CONTENT_GROUP} id={TEST_IDS.CONTENT_GROUP}>
      {sections.map((section, i) => {
        const isLastItem = i === sections.length - 1;
        return <SideSheetContentSection key={section.title} {...section} isLastItem={isLastItem} />;
      })}
    </div>
  );
};
