export const TEST_IDS = {
  CLOSE_ICON: 'CLOSE_ICON',
  HEADER_ID: 'sidesheet-header',
  FOOTER_ID: 'sidesheet-footer',
  CONTENT_ID: 'sidesheet-content-container',
  MODAL: 'sidesheet-modal',
  CONTENT_GROUP: 'sidesheet-content-group',
  CONTENT_SECTION: 'sidesheet-content-section',
};

/** This is the duration of the animation set in the CSS file ($animation-duration) */
export const CSS_ANIMATION_DURATION_MS = 300;

/**
 * In CSS the animations are set to 300ms. While testing I found that if TRANSITION_DURATION_MS === 300ms then the modal
 * popped back into view for a split second before the modal finished closing. So to avoid that let's set the timeout to
 * a value slightly less than 300ms.
 */
export const MODAL_CLOSE_DURATION_MS = CSS_ANIMATION_DURATION_MS - 15;
