import {useState, useEffect} from 'react';
import isEqual from 'lodash.isequal';
import usePrevious from '../../hooks/usePrevious';
import {
  ActionMeta,
  SelectArrayOptions,
  SelectBaseOption,
  SelectOptions,
  TUseCustomValueSelectProps,
} from '../SelectField.types';

// This constant defines actions that react-select emits that this component needs to listen to.
const REACT_SELECT_ACTIONS = {
  INPUT_CHANGE: 'input-change',
};

const getPreviewOptionLabel = (previewText: string) => `Add "${previewText}"`;

const findOptionByLabel = (label: string) => (option: SelectBaseOption) => option.label === label;

/** This hook is designed to manage custom values within a select field. It handles both single and grouped options. */
export const useCustomValueSelect = ({
  options,
  groups,
  onChange,
  multiple,
  showAddCustomValue,
}: TUseCustomValueSelectProps) => {
  // customValue holds the value typed into the input field by the user.
  const [customValue, setCustomValue] = useState('');

  // fieldOptions holds the current set of options (either groups or single options).
  const [fieldOptions, setFieldOptions] = useState(groups || options || []);

  // options/groups can be dynamic (can be coming from an API request)
  const previousOptionsSet = usePrevious(groups || options || []);
  useEffect(() => {
    const optionSet = groups || options || [];
    if (!isEqual(previousOptionsSet, optionSet)) {
      setFieldOptions(optionSet);
    }
  }, [groups, options, previousOptionsSet]);

  const handleInputChange = (inputValue: string, {action}: ActionMeta<string>) => {
    if (action === REACT_SELECT_ACTIONS.INPUT_CHANGE) {
      setCustomValue(inputValue);
    }
  };

  const handleSelectChange = (newValue: SelectOptions<any> | SelectOptions<any>[], actionMeta: ActionMeta<any>) => {
    /**
     * The new value could be an array (in multiple select case) or a single object (in single select case).
     * We convert it to an array for simplicity.
     */
    const selectedOptions = Array.isArray(newValue) ? newValue : [newValue];
    const previewOptionIndex = selectedOptions.findIndex(
      (option) => option?.label === getPreviewOptionLabel(customValue) && option.value === customValue,
    );

    if (previewOptionIndex > -1) {
      /*
        We are going to remove the preview option and replace it with a custom option. Failure
        to do so will result in both the preview option and the custom option being displayed.
      */
      selectedOptions.splice(previewOptionIndex, 1);
      const customOption = {value: customValue, label: customValue, isCustom: true};
      setFieldOptions((prevOptions: SelectOptions<any>[]) => [...prevOptions, customOption]);
      setCustomValue('');

      /*
        Call the onChange handler with the updated list of selected options and the metadata.
        If the select is a multiple select, we add the custom option to the list.
        Otherwise, we pass only the custom option as the new value.
       */
      onChange(multiple ? [...selectedOptions, customOption] : customOption, actionMeta);
      return;
    }
    onChange(newValue, actionMeta);
  };

  /** getFieldOptions prepares the options that will be shown in the dropdown, including the custom preview option. */
  const getFieldOptions = () => {
    // If the 'Add Custom Value' feature is turned on and there's a customValue set by the user, we need to show this as an option in the dropdown.
    if (showAddCustomValue && customValue) {
      // Check if the current options are grouped. This is important because grouped options have a different structure than non-grouped ones.
      const isGroup = fieldOptions.some((option) => 'options' in option);
      if (isGroup) {
        // Per Design team, we don't support custom values in grouped options.
        return fieldOptions;
      }

      // If the options are not grouped, it's a flat list of options.
      const arrayFieldOptions = fieldOptions as SelectArrayOptions;

      // Check if the custom value already exists in the options. If it doesn't, add it.
      if (!arrayFieldOptions.find(findOptionByLabel(customValue))) {
        return [...arrayFieldOptions, {value: customValue, label: getPreviewOptionLabel(customValue)}];
      }

      // If the custom value already exists, return the options as they are.
      return arrayFieldOptions;
    }

    // If there's no custom value to be added, simply return the field options as they are.
    return fieldOptions;
  };

  return {
    customValue,
    fieldOptions: getFieldOptions(),
    handleInputChange,
    handleSelectChange,
  };
};
