import React, {useEffect, useState, memo, ChangeEvent} from 'react';
import cn from 'classnames';
import {IToggleProps} from './Toggle.types';
import styles from './Toggle.scss';
import {Size} from '../base.types';

export const TEST_ID = 'StyleGuideToggle';

let inputIdCounter = 0;

const Toggle: React.FC<IToggleProps> = ({
  classes = '',
  dataTestId = TEST_ID,
  id = '',
  isDisabled = false,
  name = '',
  onToggle = () => {},
  theme = 'default',
  toggleStatus = false,
  elementSize = Size.LARGE,
}) => {
  const [inputId, setInputId] = useState(id);

  useEffect(() => {
    if (!id) {
      setInputId(`Toggle-${inputIdCounter}`);
      inputIdCounter += 1;
    }
  }, []);

  const toggle = (e: ChangeEvent<HTMLInputElement>) => {
    if (!isDisabled) {
      onToggle(e.target.checked);
    }
  };

  const toggleStyles = cn(classes, styles.switch, styles[elementSize], {
    [styles[`off_${elementSize}`]]: !toggleStatus,
    [styles[`on_${elementSize}`]]: toggleStatus,
    [styles[`theme-${theme}`]]: toggleStatus,
    [styles.disabled]: isDisabled,
  });

  return (
    <div data-testid={dataTestId} className={styles.wrapper}>
      <label className={toggleStyles} htmlFor={inputId} data-testid={`${dataTestId}-label`}>
        <input
          type="checkbox"
          className={styles.hiddenInput}
          checked={toggleStatus}
          disabled={isDisabled}
          onChange={toggle}
          id={inputId}
          name={name}
          data-testid={`${dataTestId}-checkbox`}
        />
      </label>
    </div>
  );
};

export default memo(Toggle);
