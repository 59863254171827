import React, {memo, cloneElement} from 'react';
import cn from 'classnames';
import over from 'lodash.over';
import compact from 'lodash.compact';
import first from 'lodash.first';
import {IIconComponent, IPostfixIcon, IErrorIcon, IGetIcon, ILoaderIcon} from './Input.types';
import Icon from '../Icon/Icon';
import LoadersComponent from '../Loaders/Loaders.Component';
import styles from './Input.scss';

const prevented = (fn) => (event) => {
  event.preventDefault();
  fn();
};

/*
  Loader Icon
  This will take precedence over other icon settings.
*/
const getLoaderIcon: React.FC<ILoaderIcon> = ({showLoader}) => {
  return showLoader ? <LoadersComponent loaderSize="small" containerClassName={styles.loaderContainer} /> : null;
};

/*
  Error Icon
  If there's an error, then show this icon.
*/
const getErrorIcon: React.FC<IErrorIcon> = ({hasError = false, wrapperStyles}) => {
  return hasError ? (
    <div className={wrapperStyles}>
      <Icon name="info-filled" className={cn(styles.postfixIcon, styles.errorIcon)} />
    </div>
  ) : null;
};

/*
  Icon
  Given an iconName, render this component. A ref must be passed to <InputFieldV2 /> to
  access it in the callback function.
  Usage:
  <InputFieldV2
    ...
    ref={inputRef}
    iconName="v2-green-checkmark"
    iconClass={styles.icon}
    iconOnClick={(inputRef) => { console.log('do something with the ref. maybe.') }}
  />
*/
const getIcon: React.FC<IGetIcon> = ({iconName = '', iconClass = '', iconOnClick = null, wrapperStyles, inputRef}) => {
  if (!iconName) return null;
  const onClickCB = iconOnClick
    ? prevented(() => {
        iconOnClick(inputRef);
      })
    : null;

  const component = <Icon name={iconName} className={cn(styles.postfixIcon, iconClass)} />;

  return onClickCB ? (
    <button type="button" className={cn('plainButton', styles.iconButton, wrapperStyles)} onClick={onClickCB}>
      {component}
    </button>
  ) : (
    <div className={wrapperStyles}>{component}</div>
  );
};

/*
  Custom Icon Component
  Pass a custom component to render. A ref must be passed to <InputFieldV2 /> to
  access it as a prop in the custom icon component.
  Usage:
  const SomethingCustom = ({ inputRef }) => {
    console.log(inputRef);
    return <p>Help</p>;
  };
  const inputRef = useRef();
  ....
  <InputFieldV2
    ...
    ref={inputRef}
    iconComponent={<SomethingCustom />}
  />
*/
const getIconComponent: React.FC<IIconComponent> = ({iconComponent = null, inputRef, wrapperStyles}) => {
  if (!iconComponent) return null;
  const modifiedIconComponent = cloneElement(iconComponent as React.ReactElement<any>, {
    inputRef,
  });

  return <div className={wrapperStyles}>{modifiedIconComponent}</div>;
};

/**
 Postfix Icon
 Icon to show on the right-hand side of the input.
 Notes:
 - If there is an error, the error icon will take precedence.
 */

// Helpers
const iterateIconGetters = over(getLoaderIcon, getErrorIcon, getIcon, getIconComponent);
const iterateIconGettersCustom = over(getIcon, getErrorIcon, getIconComponent);
const getIconToRender = (props) => {
  const {showError = true} = props;
  let iconGetters = iterateIconGetters;

  if (!showError) {
    iconGetters = iterateIconGettersCustom;
  }

  return first(compact(iconGetters(props))) || null;
};

const PostfixIcon: React.FC<IPostfixIcon> = ({inputRef, size, ...rest}) => {
  const wrapperStyles = cn(styles.postfixIconWrapper, {
    [styles[`input${size}`]]: true,
  });

  return getIconToRender({inputRef, wrapperStyles, ...rest});
};

export default memo(PostfixIcon);
