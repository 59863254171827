import React from 'react';
import cn from 'classnames';
import {ICircleProps} from './Radio.types';
import styles from './Radio.scss';

const Circle: React.FC<ICircleProps> = ({selected}) => {
  const circleStyles = cn(styles.circle, {
    [styles.circleSelected]: selected,
  });

  return <div className={circleStyles}>{selected && <div className={styles.innerDot} />}</div>;
};

export default Circle;
