import React from 'react';
import cn from 'classnames';
import {Size} from '../base.types';
import * as Types from './Avatar.types';
import styles from './Avatar.scss';

/* Takes n number of words and returns first/last capitalized intitials */
const getInitials = (name: string = ''): string => {
  const {0: first, length: l, [l - 1]: last} = name
    .toUpperCase()
    .split(' ')
    .map(([f]) => f);
  return `${first}${last}`;
};

const getLabel: Function = (name: string, imageAlt?: string): string =>
  imageAlt || `Your HelloTech Technician is ${name}`;
const getImage: Function = (img: string, name: string, imageAlt: string): JSX.Element => (
  <img className={styles.avatarImage} alt={getLabel(name, imageAlt)} src={img} />
);

const Avatar = ({
  name = '',
  image = '',
  className = '',
  imageAlt = '',
  size = Size.LARGE,
  dataTestId = 'StyleGuideAvatar',
}: Types.IAvatarProps) => {
  if (!name && !image) return null;

  const body = (image && getImage(image, name, imageAlt)) || (name && getInitials(name));
  const sizeClass = styles[size];

  return (
    <div className={cn(styles.avatarcontainer, sizeClass, className)} data-testid={dataTestId}>
      {body}
    </div>
  );
};

export default React.memo(Avatar);
