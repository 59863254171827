import React from 'react';
import {NoticeProps} from 'react-select';
import styles from '../SelectField.scss';

const NoOptionsMessage = ({innerProps}: NoticeProps) => {
  return (
    <div {...innerProps} className={styles.htSelectEmpty}>
      No Results
    </div>
  );
};

export default NoOptionsMessage;
