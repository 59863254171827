import React from 'react';
import cn from 'classnames';
import Grid from '../Grid/Grid';
import {CommonForm, FormContainer, Column} from './Form.types';
import styles from './Form.scss';

const FormColumn: React.FC<Column> = ({children, classes = '', ...rest}) => {
  return (
    <Grid.Column classes={cn(styles.formColumn, classes)} {...rest}>
      {children}
    </Grid.Column>
  );
};

const FormRow: React.FC<CommonForm> = ({children, classes = '', ...rest}) => {
  return (
    <Grid.Row classes={classes} {...rest}>
      {children}
    </Grid.Row>
  );
};

const Form: React.FC<FormContainer> & {Row: React.FC<CommonForm>} & {Column: React.FC<Column>} = ({
  children,
  classes = '',
  withoutFormTag = false,
  dataTestId = 'StyleGuideForm',
  ...rest
}) => {
  return (
    <Grid.FullWidth classes={classes}>
      {withoutFormTag ? (
        <div className={styles.form} data-testid={dataTestId}>
          {children}
        </div>
      ) : (
        <form className={styles.form} data-testid={dataTestId} {...rest}>
          {children}
        </form>
      )}
    </Grid.FullWidth>
  );
};

Form.Row = FormRow;
Form.Column = FormColumn;

export default Form;

/*
  This component is built on top of our Grid system, so it works
  similarly to the Grid components.

  The main differences are the form tag that is rendered and the
  bottom margins that are applied to each column. The negative margin
  is applied to offset the margin of the bottom row because the designs
  called for no bottom margin on the last row.

  Notes:
  Every child of <Form/> should be a group of <Form.Row><Form.Column> for
  style purposes.

  ------------

  Usage:

  import { Form, InputField, Button } from 'components/Elements/Form';

  ...

  <Form>
    <Form.Row>
      <Form.Column lg={6} md={4} sm={4}>
        <InputField />
      </Form.Column>
      <Form.Column lg={6} md={4} sm={4}>
        <InputField />
      </Form.Column>
    </Form.Row>
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <InputField />
      </Form.Column>
    </Form.Row>
    <Form.Row>
      <Form.Column lg={12} md={8} sm={4}>
        <Button>Submit</Button>
      </Form.Column>
    </Form.Row>
  </Form>
*/
