import React, {useEffect, useState} from 'react';
import cn from 'classnames';
// types
import {ICheckboxProps} from './Checkbox.types';
import {Size} from '../base.types';
// components
import Box from './Checkbox.Box';
// ui
import styles from './Checkbox.scss';

let inputIdCounter = 0;

/**
 Checkbox - Holds the checkmark and label

 The actual <input /> is hidden so we can customize the look of the
 checkmark box.
 */
const Checkbox: React.FC<ICheckboxProps> = ({
  children,
  label,
  checked,
  value: cbValue,
  disabled,
  indeterminate = false,
  onCheckboxChange,
  wrapperClass = '',
  classes = '',
  labelClass = '',
  boxClass = '',
  elementSize = Size.S,
  boldLabel = false,
  id = '',
  dataTestId = 'StyleGuideCheckbox',
  ...rest
}) => {
  const [inputId, setInputId] = useState(id);

  useEffect(() => {
    if (!id) {
      setInputId(`Checkbox-${inputIdCounter}`);
      inputIdCounter += 1;
    }
  }, []);

  const labelWrapperStyles = cn(styles.labelWrapper, styles[elementSize], wrapperClass, {
    [styles.disabled]: disabled,
  });

  const labelStyles = cn(styles.label, labelClass, {
    [styles.bold]: boldLabel,
  });

  return (
    <div className={classes} data-testid={dataTestId}>
      <label htmlFor={inputId} className={labelWrapperStyles}>
        <div>
          <input
            id={inputId}
            type="checkbox"
            disabled={disabled}
            checked={checked}
            onChange={onCheckboxChange?.bind(null, cbValue)}
            value={cbValue}
            className={styles.input}
            {...rest}
          />
          <Box checked={checked!} className={boxClass} indeterminate={indeterminate} />
        </div>
        {label && (
          <div>
            <span className={labelStyles}>{label}</span>
          </div>
        )}
      </label>
      {children && <div className={styles.childWrapper}>{children}</div>}
    </div>
  );
};

export default Checkbox;
