import {ReactElement, ReactNode} from 'react';
import {Size} from '../base.types';

export enum ChipTypes {
  success = 'success',
  primary = 'primary',
  neutral = 'neutral',
  muted = 'muted',
  warning = 'warning',
  default = 'neutral',
}

export interface IChipProps {
  /**
   * Elements to render with in the chip.
   */
  children: ReactElement | ReactElement[] | ReactNode[] | string;
  /**
   * Classes to apply to the container.
   */
  classes?: string | string[];
  /**
   * Test id to use on the container.
   */
  dataTestId?: string;
  /**
   * Specify the size of the chip.
   */
  size?: Size;
  /**
   * Specify the chip type.
   */
  type?: ChipTypes;
  /**
   * Specify the chip variant.
   */
  variant?: 'outlined' | 'solid';

  /**
   * Specify a backgroundColor that overrides the default or type one.
   */
  backgroundColor?: string;

  /**
   * Specify a foregroundColor (text & border) that overrides the default or type one.
   */
  foregroundColor?: string;

  /** Optional onClick callback */
  onClick?: () => void;

  /**
   * Specify a icon to render in the chip.
   */
  iconName?: string;
  contentShape?: 'circle';
}

export type GetStyles = Pick<IChipProps, 'classes' | 'size' | 'type' | 'variant'>;

export type TChipContentShape = {
  type: 'circle';
};

export type TChipColorContext = {
  foregroundColor: string;
  type: string;
};
