import React, {useState, useCallback, useEffect} from 'react';
import cn from 'classnames';
import Icon from '../Icon/Icon';
import * as Types from './Accordion.types';
import styles from './Accordion.scss';

const DEFAULT_ICON_POSITION = 'left';

type DetermineSettingType = <T>(parentProp: T, childProp: T, defaultVal: Omit<T, null>) => Omit<T, null>;
const determineSetting: DetermineSettingType = (parentProp, childProp, defaultVal) => {
  const isTypeLikeDefault = (prop: any) => typeof prop === typeof defaultVal;

  if (!isTypeLikeDefault(parentProp) && !isTypeLikeDefault(childProp)) {
    return defaultVal;
  }
  return isTypeLikeDefault(childProp) ? childProp : parentProp;
};

const Accordion: React.FC<Types.IAccordionProps> = ({
  title = '',
  children,
  childIconPosition,
  className = '',
  dataTestId = 'StyleGuideAccordion',
  onToggle = () => {},
  opened = false,
  iconPosition,
  groupHasOpenItem = false,
  withBottomBorder = false,
  noContentPadding,
  noIndentPadding,
  noChildContentPadding,
  noChildIndentPadding,
  selectClick,
  index,
  alwaysOpen,
  muted,
  iconVertical,
}) => {
  const [showMore, setShowMore] = useState<boolean>(opened);
  const iconName = iconVertical ? 'chevron' : 'chevron-right';

  useEffect(() => {
    setShowMore(opened);
    if (alwaysOpen) setShowMore(!!alwaysOpen);
  }, [opened]);

  const toggleAccordion = useCallback(() => {
    if (alwaysOpen || muted) return;

    const shouldShow = !showMore;
    setShowMore(shouldShow);
    onToggle(shouldShow);
  }, [showMore, onToggle]);

  const determinedIconPosition = determineSetting<Types.IconPositionType>(
    childIconPosition,
    iconPosition,
    DEFAULT_ICON_POSITION,
  );

  const accordionContainerStyles = cn(styles.accordionContainer, className, 'paddingY-small1', {
    [styles.withBottomBorder]: withBottomBorder,
  });
  const titleContainerStyles = cn(styles.titleContainer, {
    [styles.faded]: groupHasOpenItem && !opened,
    [styles.muted]: muted,
  });
  const rotationStyles = cn({
    [styles.chevronIcon]: !iconVertical,
    [styles.chevronIcon_vertical]: iconVertical,
    [styles.rotate]: showMore,
  });
  const contentStyles = cn('p1', styles.accordionContent, {
    [styles.closed]: !showMore,
    [styles.noContentPadding]: determineSetting<Types.PaddingType>(noChildContentPadding, noContentPadding, false),
    [styles.noIndentPadding]: determineSetting<Types.PaddingType>(noChildIndentPadding, noIndentPadding, false),
  });
  const flexOrder = determinedIconPosition === 'left' ? null : styles.iconOrder;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child as React.ReactElement<any>, {
        ...(selectClick && {onClick: selectClick}),
        index,
      });
    }
    return child;
  });

  return (
    <div className={accordionContainerStyles} data-testid={dataTestId}>
      <button
        type="button"
        className={cn(
          'plainButton',
          titleContainerStyles,
          determinedIconPosition === 'left' ? null : styles.spaceBetween,
        )}
        onClick={toggleAccordion}
      >
        <Icon name={iconName} className={cn(rotationStyles, flexOrder, styles.chevronIcon)} />
        <h5 className={styles.title}>{title}</h5>
      </button>
      <div className={contentStyles}>{childrenWithProps}</div>
    </div>
  );
};

export default React.memo(Accordion);
