import React from 'react';
import cn from 'classnames';
import {IPanelProps} from './Panel.types';
import useWithStyles from '../utils/useWithStyles';
import styles from './Panel.scss';

const Panel = (props: IPanelProps) => {
  const {children, className = '', dataTestId = 'StyleGuidePanel'} = props;
  const panelClasses = cn(styles.panelContainer, useWithStyles(props, styles), className);
  if (!children) return null;

  return (
    <section data-testid={dataTestId} className={panelClasses}>
      {children}
    </section>
  );
};

export default Panel;
