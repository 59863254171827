import React, {useMemo, useRef} from 'react';
import cn from 'classnames';
import {useElementStatus} from '../DonutChart/DonutChart.hooks';
import {TipBox} from '../Tooltip/Tooltip';

import styles from './ProgressBarChart.scss';

import {IProgressBarChartProps} from './ProgressBarChart.types';

const ProgressBarChartSegment = ({segment, dataTestId}) => {
  const ref = useRef<HTMLDivElement>();
  const {hovered: show} = useElementStatus({ref});

  const style = useMemo(
    () => ({
      width: `${segment.percentage}%`,
      backgroundColor: segment.color,
    }),
    [segment.color, segment.percentage],
  );

  return (
    <div ref={ref} className={styles.segment} style={style} data-testid={dataTestId}>
      {segment.label && <TipBox show={show} content={segment.label} position="bottom" align="center" />}
    </div>
  );
};

const ProgressBarChart: React.FC<IProgressBarChartProps> = ({
  segments = [],
  featuredPrefix,
  featuredSuffix,
  hideFeaturedLabel,
  dataTestId,
}) => {
  const featured = useMemo(
    () => (!hideFeaturedLabel ? segments.find((segment) => segment.featured) || segments[0] : false),
    [segments, hideFeaturedLabel],
  );

  return (
    <div className={styles.container} data-testid={dataTestId}>
      {featured && (
        <div className={cn('p2', styles.label)}>
          {featuredPrefix || ''} {Math.round(featured.percentage)}% {featuredSuffix || ''}
        </div>
      )}
      <div className={styles.bar}>
        {segments.map((segment, index) => (
          <ProgressBarChartSegment
            key={`segment-${index + 0}`}
            segment={segment}
            dataTestId={segment.percentage !== -1 ? `${dataTestId}-segment-${index}` : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default ProgressBarChart;
