import React, {useCallback, useRef, useState, useEffect} from 'react';
import cn from 'classnames';
import throttle from 'lodash.throttle';
import useEventListener from '../hooks/useEventListener';
import styles from './Tooltip.scss';
import {ITooltipProps, TipBoxProps} from './Tooltip.types';

export const TipBox = ({show, content, position, align}: TipBoxProps) => {
  const [visible, setVisible] = useState<boolean>(false);
  const tipBoxRef = useRef<HTMLDivElement>();
  const modifiedPosition = useRef<string>(position);
  const modifiedAlignment = useRef<string>(align);

  /*
    This applies rudimentary logic to detect if the tooltip is extending
    beyond the browser window. If it extends beyond, then change its position
    and alignment.
  */
  useEffect(() => {
    if (show) {
      const {left: tipBoxLeft, right: tipBoxRight} = tipBoxRef.current.getBoundingClientRect();
      const {innerWidth: windowWidth} = window;

      if (position === 'top' || position === 'bottom') {
        if (tipBoxRight > windowWidth) {
          modifiedAlignment.current = 'right';
        } else if (tipBoxLeft < 0) {
          modifiedAlignment.current = 'left';
        }
      } else if (position === 'left') {
        if (tipBoxLeft < 0) {
          modifiedAlignment.current = 'left';
          modifiedPosition.current = 'bottom';
        }
      } else if (position === 'right') {
        if (tipBoxRight > windowWidth) {
          modifiedAlignment.current = 'right';
          modifiedPosition.current = 'bottom';
        }
      }
      setVisible(true);
    } else {
      setVisible(false);
      modifiedPosition.current = position;
      modifiedAlignment.current = align;
    }
  }, [show]);

  const positionStyle = styles[modifiedPosition.current];
  const alignStyle = styles[`align-${modifiedAlignment.current}`];
  const visibilityStyle = visible ? '' : styles.hidden;

  return (
    <div data-testid="tooltip-box" className={cn(styles.scaffoldingBox, positionStyle, alignStyle, visibilityStyle)}>
      <div className={cn(styles.positioningBox, positionStyle, alignStyle)}>
        <div className={cn(styles.hoverScaffoldingBox, positionStyle)}>
          <div ref={tipBoxRef} className={styles.tipBox}>
            <p className="caption">{content}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Tooltip: React.FC<ITooltipProps> = ({
  children,
  hoverDuration = 200,
  useHover = true,
  content = '',
  position = 'bottom',
  align = 'center',
  wrapperClass = '',
  dataTestId = 'StyleGuideTooltip',
}) => {
  const [showTip, setShowTip] = useState(false);
  const touchEnabled = useRef<boolean>(false);
  const hoverEnabled = useRef<boolean>(false);
  const timeoutId = useRef<ReturnType<typeof setTimeout>>();
  const containerRef = useRef<HTMLDivElement>();

  const onTouchStart = useCallback(() => {
    touchEnabled.current = true;
  }, []);

  const onMouseEnter = useCallback(() => {
    if (!touchEnabled.current && useHover) {
      timeoutId.current = setTimeout(() => setShowTip(true), hoverDuration);
      hoverEnabled.current = true;
    }
    touchEnabled.current = false;
  }, []);

  const onClick = useCallback(() => {
    if (!hoverEnabled.current) {
      setShowTip(!showTip);
    }
  }, [showTip]);

  const onMouseLeave = useCallback(() => {
    clearTimeout(timeoutId.current);
    if (hoverEnabled.current && showTip && useHover) {
      setShowTip(false);
    }
    hoverEnabled.current = false;
  }, [showTip]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId.current);
    };
  }, []);

  /*
    Close self on touch-enabled devices if user taps elsewhere.
    This method isn't great for browser performance if there are many
    instances of Tooltip on a page.
  */
  const closeOtherTooltips = useCallback((e) => {
    if (!containerRef.current.contains(e.target)) {
      setShowTip(false);
    }
  }, []);

  useEventListener({eventName: 'touchstart', handler: closeOtherTooltips});

  /**
   Close the tooltip if the user scrolls
   */
  const handleCloseOnScroll = throttle(() => {
    if (showTip) {
      setShowTip(false);
    }
  }, 500);

  useEventListener({eventName: 'scroll', handler: handleCloseOnScroll});

  return (
    <div
      onTouchStart={onTouchStart}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      ref={containerRef}
      className={cn(styles.wrapper, wrapperClass)}
      data-testid={dataTestId}
    >
      {children}
      <TipBox show={showTip} content={content} position={position} align={align} />
    </div>
  );
};

export default Tooltip;
