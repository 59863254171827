import {useEffect, useRef} from 'react';

export default function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// https://github.com/Andarist/use-previous
