import React, {ReactElement} from 'react';
import cn from 'classnames';
import styles from './Loaders.scss';

interface ILoaderComponentProps {
  /**
   * Class name to apply to the loading animation.
   */
  className?: string;
  /**
   * Class name to apply to the container.
   */
  containerClassName?: string;
  /**
   * This only controls the size for the spinning bubbles animation.
   */
  loaderSize?: 'small' | null;
  /**
   * Display a message below the loader.
   */
  message?: string;
  /**
   * Class name for the message.
   */
  messageClassName?: string;
  /**
   * Display the primary animation, which is a column of loaders. Set to `false` to
   * display the spinning bubbles animation.
   */
  primary?: boolean;
}

const LoadersComponent: React.FC<ILoaderComponentProps> = ({
  primary = false,
  className = '',
  message = '',
  messageClassName = '',
  loaderSize = '',
  containerClassName = '',
} = {}): ReactElement => {
  const loaderType = cn(className, {
    [styles.columnLoader]: primary,
    [styles.spinningBubbles]: !primary,
    [styles[loaderSize]]: true,
  });

  return (
    <div className={cn(styles.loadingComponentContainer, containerClassName)}>
      <div className={loaderType} />
      {message && <div className={cn('p2', messageClassName)}>{message}</div>}
    </div>
  );
};

export default LoadersComponent;
