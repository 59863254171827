import {useState, useEffect, useCallback, useMemo, ReactElement, Children, ReactNode} from 'react';
import throttle from 'lodash.throttle';
import sortBy from 'lodash.sortby';
import useEventListener from '../hooks/useEventListener';
import {isMobile, isTablet} from './core';

const OPTIONS_LIMIT = 7;
const WIN_SM: string = 'windowSmall';
const WIN_MD: string = 'windowMedium';
const WIN_LG: string = 'windowLarge';

const NUM_COLS = {
  [WIN_SM]: 1,
  [WIN_MD]: 2,
  [WIN_LG]: 3,
};

const COL_PROPS = {
  [WIN_SM]: {sm: 4},
  [WIN_MD]: {md: 4},
  [WIN_LG]: {lg: 4},
};

// Break down into groups. Lodash's chunk behavior wasn't a great fit.
type BreakIntoGroupsType = (
  list: ReactElement[],
  numGroups: number,
  finalList?: ReactElement[][],
) => ReactElement[][] | ReactElement[];

const breakIntoGroups: BreakIntoGroupsType = (list = [], numGroups = 1, finalList = []) => {
  if (!Array.isArray(list) || numGroups < 0) return list;
  const listLength = list.length;
  const listQuotient = listLength / numGroups;
  const sliceValue = listLength % numGroups === 0 ? listQuotient : listQuotient + 1;
  finalList.push(list.slice(0, sliceValue));
  const remaining = list.slice(sliceValue);
  if (remaining.length) {
    breakIntoGroups(remaining, numGroups - 1, finalList);
  }
  return finalList;
};

const determineWindowType = () => {
  if (isMobile()) {
    return WIN_SM;
  }
  if (isTablet()) {
    return WIN_MD;
  }
  return WIN_LG;
};

/*
  We want to group the children into columns if children.length is greater than
  the limit (7).

  For desktop, split into 3 columns.
  For tablet, split into 2 columns.
  For mobile, split into 1 column.
*/

type PropsType = {
  children: ReactElement | ReactElement[] | ReactNode[];
  disableSort: boolean;
  disableGrouping: boolean;
  optionsLimit?: number;
};

const useGetGroupedChildren = ({children, disableSort = false, disableGrouping = false, optionsLimit}: PropsType) => {
  const [windowType, setWindowType] = useState(WIN_SM);

  useEffect(() => {
    setWindowType(determineWindowType());
  }, []);

  const handleResize = useCallback(
    throttle(() => {
      setWindowType(determineWindowType());
    }, 300),
    [],
  );

  useEventListener({eventName: 'resize', handler: handleResize});

  const {groupedChildren, columnProps} = useMemo(() => {
    if (Children.count(children) > (optionsLimit || OPTIONS_LIMIT) && !disableGrouping) {
      // Break options into columns
      const sortFn = disableSort ? (arr: ReactElement[]) => arr : sortBy;
      const sortedChildren: ReactElement[] = sortFn(children, (child: ReactElement) => child.props.label);
      const modifiedColumnProps = {...COL_PROPS[windowType]};
      const modifiedGroupedChildren = breakIntoGroups(sortedChildren, NUM_COLS[windowType]);
      return {columnProps: modifiedColumnProps, groupedChildren: modifiedGroupedChildren};
    }
    return {columnProps: {}, groupedChildren: [children]};
  }, [children, optionsLimit, disableGrouping, disableSort, windowType]);

  return {
    groupedChildren,
    columnProps,
  };
};

export default useGetGroupedChildren;
