import React, {cloneElement, ReactElement, memo, ReactNode} from 'react';
import {deepMap} from 'react-children-utilities';
import Grid from '../Grid/Grid';
import Label from '../Parts/Label';
import Checkbox from './Checkbox';
import useGetGroupedChildren from '../utils/useGetGroupedChildren';
// types
import {ICheckboxGroupProps} from './Checkbox.types';
import {Size} from '../base.types';

const CheckboxGroup: React.FC<ICheckboxGroupProps> = ({
  label,
  labelComponent,
  dangerouslySetLabel,
  children,
  value: checkedValues,
  onChange,
  disableSort = true,
  disableGrouping = true,
  containerClassName = '',
  columnClassName = '',
  rowClassName = '',
  elementSize = Size.S,
  dataTestId = 'StyleGuideCheckboxGroup',
  optionsLimit,
}) => {
  // The callback for when a checkbox is clicked
  const onCheckboxChange = (checkboxValue: any, event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      onChange([...checkedValues, checkboxValue]);
    } else {
      onChange(checkedValues.filter((v) => v !== checkboxValue));
    }
  };

  // We want to pass down extra props to the children (Checkbox)
  const childrenWithExtras = deepMap(children, (child: ReactNode) => {
    if (child && (child as ReactElement).type === Checkbox) {
      return cloneElement(child as ReactElement, {
        ...(child as ReactElement).props,
        checked: checkedValues.includes((child as ReactElement).props.value),
        onCheckboxChange,
        elementSize,
      });
    }
    return child;
  });

  // Group the children if necessary. See the utils
  const {groupedChildren, columnProps} = useGetGroupedChildren({
    children: childrenWithExtras,
    disableSort,
    disableGrouping,
    optionsLimit,
  });

  return (
    <div className={containerClassName} data-testid={dataTestId}>
      <Label
        elementSize={elementSize}
        label={label}
        labelComponent={labelComponent}
        dangerouslySetLabel={dangerouslySetLabel}
      />
      <Grid.FullWidth>
        <Grid.Row classes={rowClassName}>
          {(groupedChildren as ReactElement[][]).map((group: ReactElement[], i: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid.Column classes={columnClassName} {...columnProps} key={i}>
              {group}
            </Grid.Column>
          ))}
        </Grid.Row>
      </Grid.FullWidth>
    </div>
  );
};

export default memo(CheckboxGroup);

/**
 CheckboxGroup

 This group is used with Checkbox. If the options count exceed a certain limit,
 the options will be displayed in multiple columns instead of a single column. Also,
 the options will be sorted alphabetically when they are broken down into columns.
 Props are provided to override these default settings.

 Note:
 Checkbox must be a direct child of CheckboxGroup for this to work, or
 just take extra care if you're using a custom checkbox component.

 Props:
 label - A label of type string to render.
 labelComponent - A custom component to render for the label.
 dangerouslySetLabel - A string to dangerously set its inner html.
 value - An array of checked values
 onChange - Function to call when selecting/deselecting. The list of checked values is the first argument.
 disableSort - Disable sorting of list when breaking into multiple columns.
 disableGrouping - Disable breaking into multiple columns. Show options in a single column regardless of options count.
 size - Form size. Affects container label and list spacing

 ---------

 General Usage:

 import { Checkbox, CheckboxGroup } from 'components/Elements/Checkbox';

 ...

 const [checkedValues, setValues] = useState([]);
 const onChange = (allCheckedValues) => {
    setValues(allCheckedValues);
  };

 return (
 <CheckboxGroup
 label="An Optional Label"
 value={checkedValues}
 onChange={onChange}
 containerClassName={styles.container}
 >
 <Checkbox value={1} label="Number 1" />
 <Checkbox value={2} label="Number 2" />
 <Checkbox value={3} label="Number 3" boldLabel />
 <Checkbox value={4} label="Number 4">
 <p className="caption">Some custom component to show below the label</p>
 </Checkbox>
 </CheckboxGroup>
 );
 */
