import { useEffect, useState } from "react";

export const useElementStatus = ({ref}) => {
  const [hovered, setHovered] = useState(false);
  
  useEffect(() => {
    const { current } = ref;
    if (!current) return;
    const enterHandler = () => {
      setHovered(true);
    }
    const leaveHandler = () => setHovered(false);

    current.addEventListener('mouseenter', enterHandler);
    current.addEventListener('mouseleave', leaveHandler);

    // eslint-disable-next-line consistent-return
    return () => {
      current.removeEventListener('mouseenter', enterHandler);
      current.removeEventListener('mousleave', leaveHandler);
    };
  }, [ref]);

  return {hovered};
};