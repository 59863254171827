import React from 'react';
import styles from './ErrorMessage.scss';

interface IErrorMessage {
  error: string | undefined | null;
}
/**
  ErrorMessage

  Basic component to render error messages
*/
const ErrorMessage: React.FC<IErrorMessage> = ({error}) => {
  return error ? (
    <div data-testid="StyleGuideErrorMessage" role="alert">
      <p className={styles.error}>{error}</p>
    </div>
  ) : null;
};

export default ErrorMessage;
