import React from 'react';
import {MultiValueGenericProps} from 'react-select';
import cn from 'classnames';
import {ISelectProps} from '../SelectField.types';
import styles from '../SelectField.scss';

const MultiValueLabel = ({innerProps, children, selectProps}: MultiValueGenericProps) => {
  const {elementSize, dataTestId} = selectProps as ISelectProps;
  const multiValueLabelClassName = styles[`multiValueLabel${elementSize}`];

  return (
    <div
      {...innerProps}
      data-testid={`${dataTestId}-MultiValueLabel`}
      className={cn(styles.htMultiValueLabel, multiValueLabelClassName)}
    >
      {children}
    </div>
  );
};

export default MultiValueLabel;
