import React from 'react';
import cn from 'classnames';
import {MultiValueRemoveProps} from 'react-select';
import Icon from '../../Icon/Icon';
import {ISelectProps} from '../SelectField.types';
import styles from '../SelectField.scss';

const MultiValueRemove = ({innerProps, selectProps}: MultiValueRemoveProps) => {
  const {elementSize} = selectProps as ISelectProps;
  const multiRemoveClassName = styles[`multiValueRemove${elementSize}`];

  return <Icon name="v2-close-icon" {...innerProps} className={cn(styles.htMultiValueRemove, multiRemoveClassName)} />;
};
export default MultiValueRemove;
