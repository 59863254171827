/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {ReactNode} from 'react';
import cn from 'classnames';
import {Size} from '../../base.types';
import styles from './Label.scss';

interface ILabel {
  label?: string | ReactNode | null | undefined;
  labelComponent?: React.ReactNode | null | undefined;
  dangerouslySetLabel?: string | null | undefined;
  inputId?: string;
  elementSize?: Size;
}

/**
  Label

  Accepts a string or a custom component
*/
const Label: React.FC<ILabel> = ({
  label,
  labelComponent = null,
  dangerouslySetLabel = null,
  inputId,
  elementSize = Size.S,
}) => {
  if (!(label || labelComponent || dangerouslySetLabel)) return null;

  const labelWrapperStyles = cn(styles.labelWrapper, {
    [styles.medium]: elementSize !== Size.L,
    [styles.large]: elementSize === Size.L,
  });

  const labelStyles = cn({
    [`input-label-${elementSize}`]: true,
  });

  let labelToRender: ReactNode = (
    <label htmlFor={inputId} className={labelStyles}>
      {label}
    </label>
  );

  if (labelComponent) labelToRender = labelComponent;

  if (dangerouslySetLabel) {
    labelToRender = (
      <label htmlFor={inputId} className={labelStyles} dangerouslySetInnerHTML={{__html: dangerouslySetLabel}} />
    );
  }

  return <div className={labelWrapperStyles}>{labelToRender}</div>;
};

export default Label;
