import React, {memo} from 'react';
import ReactModal from 'react-modal';
import cn from 'classnames';
import {Size} from '../base.types';
import Icon from '../Icon/Icon';
import {IPlainModalProps} from './PlainModal.types';
import styles from './PlainModal.scss';

const PlainModal: React.FC<IPlainModalProps> = ({
  children,
  dataTestId = 'StyleGuidePlainModal',
  elementSize = Size.M,
  hide = () => {},
  isVisible = false,
  modalClassName = '',
  modalCloseButtonClasses = '',
  modalCloseIconClasses = '',
  onRequestClose = () => {},
  withMobilePadding = false,
  ...props
}) => {
  const slideAnimation = isVisible ? styles.slideUp : styles.slideDown;
  const overlayStyles = cn(styles.overlay, {
    [styles.withMobilePadding]: withMobilePadding,
  });
  const modalStyles = cn(
    styles.commonModalStyles,
    {
      [styles.modalLarge]: elementSize === Size.L,
      [styles.modalMedium]: elementSize === Size.M,
      [styles.modalSmall]: elementSize === Size.S || elementSize === Size.XS,
    },
    slideAnimation,
    modalClassName,
  );
  const closeButtonStyles = cn(styles.modalCloseButton, modalCloseButtonClasses);
  const closeIconStyles = cn(styles.modalCloseIcon, modalCloseIconClasses);

  return (
    <ReactModal
      ariaHideApp={false}
      isOpen={isVisible}
      onRequestClose={onRequestClose}
      overlayClassName={overlayStyles}
      className={modalStyles}
      testId={dataTestId}
      {...props}
    >
      <button
        type="button"
        className={closeButtonStyles}
        onClick={hide}
        data-testid={`${dataTestId}-close`}
        aria-label="Close Modal Icon"
      >
        <Icon name="v2-close-icon" className={closeIconStyles} />
      </button>
      <div className={styles.childrenContainer} data-testid={`${dataTestId}-childrenContainer`}>
        {children}
      </div>
    </ReactModal>
  );
};

export default memo(PlainModal);
