import React from 'react';
import cn from 'classnames';
import Grid from '../Grid/Grid';
import Icon from '../Icon/Icon';
import {IEmptyStateProps} from './EmptyState.types';
import styles from './EmptyState.scss';

const EmptyState: React.FC<IEmptyStateProps> = ({
  title,
  text,
  iconName,
  iconStyles,
  className,
  showButtonContainer,
  buttonContainerClass,
  children,
  dataTestId = 'StyleGuideEmptyState',
}) => {
  return (
    <Grid.Fluid classes={cn(className, styles.container)} dataTestId={dataTestId}>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column classes={cn(iconStyles, styles.iconContainer)} sm={4} md={8} lg={12}>
          <Icon name={iconName} className={styles.icon} />
        </Grid.Column>
      </Grid.Row>
      <Grid.Row classes={styles.customRow}>
        <Grid.Column sm={4} md={8} lg={12}>
          <h4 className={cn('paddingTop-small1', styles.header)}>{title}</h4>
        </Grid.Column>
      </Grid.Row>
      {text && (
        <Grid.Row classes={styles.customRow}>
          <Grid.Column sm={4} md={6} mdOffset={1} lg={12} classes={styles.offsetOverride}>
            <p className="p1 n700 paddingTop-tiny1">{text}</p>
          </Grid.Column>
        </Grid.Row>
      )}
      {showButtonContainer && (
        <Grid.Row>
          <Grid.Column sm={4} md={8} lg={12} classes={cn(buttonContainerClass, styles.button, 'paddingTop-small1')}>
            {children}
          </Grid.Column>
        </Grid.Row>
      )}
    </Grid.Fluid>
  );
};

export default EmptyState;
