import React, {forwardRef} from 'react';
import cn from 'classnames';
import {CalloutBoxProps} from './CalloutBox.types';
import styles from './CalloutBox.scss';

const DefaultTextContentContainer = ({children}: {children: React.ReactNode}) => (
  <div className={cn(styles.textContent, 'paddingY-small')}>{children}</div>
);

export const CalloutBoxThemes = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  CRITICAL: 'critical',
  SUCCESS: 'success',
  WARNING: 'warning',
};

const AlertBox = forwardRef<HTMLElement, CalloutBoxProps>((props, ref) => {
  const {
    children,
    className,
    theme = CalloutBoxThemes.DEFAULT,
    header,
    text,
    textStyles,
    textContentContainer: TextContentContainer = DefaultTextContentContainer,
  } = props;

  const themeStyles = {
    [styles.primary]: theme === CalloutBoxThemes.PRIMARY,
    [styles.critical]: theme === CalloutBoxThemes.CRITICAL,
    [styles.success]: theme === CalloutBoxThemes.SUCCESS,
    [styles.warning]: theme === CalloutBoxThemes.WARNING,
  };

  const containerStyles = cn(styles.container, className, themeStyles);
  const highlightBarStyles = cn(styles.highlightBar, themeStyles);
  const headerStyles = cn(styles.header, text && 'marginBottom-tiny1', themeStyles);
  const textClasses = cn(styles.text, 'p2', styles.textContent, themeStyles, textStyles);
  const textContainerStyles = cn(styles.textContainer, 'paddingX-small');

  return (
    <section className={containerStyles} ref={ref}>
      <div className={highlightBarStyles} />
      <div className={textContainerStyles}>
        <TextContentContainer>
          {header && <h6 className={headerStyles}>{header}</h6>}
          <p className={textClasses} dangerouslySetInnerHTML={{__html: text}} />
        </TextContentContainer>
        {children && <div className="paddingBottom-small">{children}</div>}
      </div>
    </section>
  );
});

AlertBox.displayName = 'CalloutBox';
const CalloutBox = AlertBox;

export default CalloutBox;
