export type BaseAnyFunction = (...any: any[]) => any;

export enum Size {
  XS = 'xsmall',
  S = 'small',
  M = 'medium',
  L = 'large',
  XSMALL = 'xsmall',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export interface IHash<T> {
  [key: string]: T;
}
