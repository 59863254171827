import React from 'react';
import cn from 'classnames';
import * as Types from './Icon.types';

const Icon: React.FC<Types.IIconProps> = ({
  name,
  className = '',
  dataTestId = 'StyleGuideIcon',
  render,
  style,
  children,
  ...rest
}) => {
  const icon = name ? name.replace(/^ht-/i, '') : 'logo';
  const styles = cn(className, 'ht-icon', `ht-${icon}`);
  const props = {style, className: styles, children};
  if (render) return render(props);
  return <i data-testid={dataTestId} {...props} {...rest} />;
};

export default Icon;
