import React, {ReactElement} from 'react';
import {OptionProps} from 'react-select';
import cn from 'classnames';
import styles from '../SelectField.scss';

const removeHTMLTags = (elem: ReactElement) => {
  // eslint-disable-next-line no-underscore-dangle
  const str = elem.props?.dangerouslySetInnerHTML.__html ?? '';
  return str.replace(/<(\w+)>(\w*)<\/\1>/g, '$2');
};

const Option = ({innerProps, isSelected, isFocused, children, selectProps, data}: OptionProps<any>) => {
  const showCustomStyle = Boolean(selectProps?.options.find((o) => o.value === 0)); // give a fallback
  const {subtext} = data;

  return (
    <div
      data-testid={removeHTMLTags(children as ReactElement)}
      {...innerProps}
      className={cn(styles.htSelectOption, {
        selected: isSelected || isFocused,
        customSelection: showCustomStyle,
      })}
    >
      {children}
      {subtext && <p className={styles.htSelectOptionSubtext}>{subtext}</p>}
    </div>
  );
};

export default Option;
