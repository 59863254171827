import React from 'react';
import LoadersComponent from './Loaders.Component';
import styles from './Loaders.scss';

interface ILoadersPage {
  /**
   * Class name to apply to the loader wrapper.
   */
  className?: string;
  /**
   * Display the primary animation, which is a column of loaders. Set to `false` to
   * display the spinning bubbles animation.
   */
  primary?: boolean;
}

/**
 * Show a loader that's intended to be used as a full page loader. The parent container
 * should have `position: relative` set, which will allow the loader to self-center.
 */
const LoadersPage: React.FC<ILoadersPage> = ({className = '', primary = false}) => (
  <div className={`${styles.wrapper} ${className}`}>
    <LoadersComponent primary={primary} />
  </div>
);

export default LoadersPage;
