/* eslint-disable react/jsx-props-no-spreading */
import React, {useEffect, useState, useRef} from 'react';
import cn from 'classnames';
// Hooks
import {useSpring, animated} from '@react-spring/web';
import useEventListener from '../hooks/useEventListener';
// Types
import {AnimatedCalloutBoxTypes, FadeInTextContentTypes} from './AnimatedCalloutBox.types';
// Components & Style
import CalloutBox from '../CalloutBox/CalloutBox';
import styles from '../CalloutBox/CalloutBox.scss';

export const ANIMATION_DURATION = {
  SLIDE: 200,
};

const FadeInTextContent = ({children, isParentVisible}: FadeInTextContentTypes) => {
  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    if (isParentVisible) setStartAnimation(true);
  }, [isParentVisible]);

  const {opacity} = useSpring({
    opacity: startAnimation ? 1 : 0,
  });

  return (
    <animated.div className={cn(styles.textContent, 'paddingY-small')} style={{opacity}}>
      {children}
    </animated.div>
  );
};

const AnimatedCalloutBox = ({visible, wrapperClassName, ...rest}: AnimatedCalloutBoxTypes) => {
  const callBoxRef = useRef<HTMLElement & {offsetHeight: string}>();

  const startHeight = '0';
  const [contentHeight, setContentHeight] = useState(startHeight);

  const setNewHeight = () => {
    if (callBoxRef.current) {
      return setContentHeight(callBoxRef.current.offsetHeight || '0');
    }
    return null;
  };

  useEventListener({eventName: 'resize', handler: setNewHeight});

  useEffect(() => {
    setNewHeight();
  }, []);

  const expand = useSpring({
    config: {duration: ANIMATION_DURATION.SLIDE},
    height: visible ? `${contentHeight}px` : `${startHeight}px`,
  });

  // Wrap the content container in a closure so we can pass visible state from parent
  const TextContentContainer = (props) => <FadeInTextContent isParentVisible={visible} {...props} />;

  const animatedDivStyles = cn(styles.animatedContainer, {[wrapperClassName]: visible});

  return (
    <animated.div style={expand} className={animatedDivStyles}>
      <CalloutBox {...rest} textContentContainer={TextContentContainer} ref={callBoxRef} />
    </animated.div>
  );
};

export default AnimatedCalloutBox;
